html, #root{
  width: 100%;
  min-height: 100vh;
  font-size: 100%;
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Aleo', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFC300;
  overflow: auto;
  text-align: center;
  word-spacing: normal;
}
/*######################################################### FONT ##################################################################*/
/*
@font-face {
  font-family: 'bold';
  src: url(./fonts/aleo-bold-webfont.ttf);
}

@font-face {
  font-family: 'standard';
  src: url(./fonts/aleo-light-webfont.ttf);
}

@font-face {
  font-family: 'italics';
  src: url(./fonts/aleo-lightitalic-webfont.ttf);
}
*/

@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Aleo&display=swap');

.title{
  font-family: 'Bungee Shade';
  font-size: 6vmax;
  color: #2B2E33;
}

.sub-title{
  font-family: 'Aleo';
  font-size: 1.5vmax;
  color: #2B2E33;
}

/*######################################################### FONT ##################################################################*/
/*######################################################### ELEMENT SELECTORS ##################################################################*/
/*######################################################### ELEMENT SELECTORS ##################################################################*/
/*######################################################### ID SELECTORS ##################################################################*/
#home-container{
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#about-container{
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#work-container{
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#skills-container{
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#resume-container{
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

#main-body{
  margin:auto;
  width: 100%;
}

/*######################################################### ID SELECTORS ##################################################################*/
/*######################################################### CLASS SELECTORS ##################################################################*/

.hidden {
  visibility: hidden;
}

.none {
  display: none;
}

.container{
  display: grid;
}

/*Mobile - height should be 40px for 600px and up as it will be a single row*/
.header{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height:70px;
  box-sizing: border-box;
  padding-top: 17px;
  font-family: 'Aleo';
}

.header a {
  color: black;
  text-decoration: none;
  padding: 0px 8px;
}

.header a:hover{
  color:red;
}

.footer{
  text-align: center;
  position: relative;
  font-family: 'standard';
  height: 40px;
  padding-top: 2px;
  box-sizing: border-box;
}

.footer a{
  padding: 0px 8px;
}

/*
.animation {
 animation-name: test;
 animation-duration: 2s;
 animation-fill-mode: forwards;
}

@keyframes test {
  0% {opacity: 0;}
  12.5% {opacity: 0.25;}
  25% {opacity: 0.5;}
  37.5% { opacity: 0.75;}
  50% { opacity: 1;}
  62.5% { opacity: 0.75;}
  75% { opacity: 0.5;}
  87.5% { opacity: 0.25;}
  100% {opacity: 0;}
}
*/

.about-main-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.slide-container{
  display:flex;
  flex-direction: column;
  margin:auto;
  max-width: 800px;
}

.slide-close{
  display: flex;
  justify-content: right;
}

.slide-display{
  display: flex;
  height: 150px;
  justify-content: center;
}

.slide-display-item{
  display:block;
  width: 125px;
  height: 125px;
  margin: 0px 15px;
}

/*Mobile*/
.slide-display-item-first{
  display:none;
  width: 125px;
  height: 125px;
  margin: 0px 15px;
}

/*Mobile*/
.slide-display-item-last{
  display:none;
  width: 125px;
  height: 125px;
  margin: 0px 15px;
}

.slide-display-item-running{
  display:block;
  width: 25vw;
  max-width: 125px;
  height: 25vw;
  max-height: 125px;
  background-size: cover;
  background-image: url('images/icons/running-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}

.slide-display-item-music{
  display:block;
  width: 25vw;
  max-width: 125px;
  height: 25vw;
  max-height: 125px;
  background-size: cover;
  background-image: url('./images/icons/music-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}

.slide-display-item-basketball{
  display:block;
  width: 25vw;
  max-width: 125px;
  height: 25vw;
  max-height: 125px;
  background-size: cover;
  background-image: url('./images/icons/basketball-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}

.slide-display-item-steak{
  display:block;
  width: 25vw;
  max-width: 125px;
  height: 25vw;
  max-height: 125px;
  background-size: cover;
  background-image: url('./images/icons/steak-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}

.slide-display-item-travel{
  display:block;
  width: 25vw;
  max-width: 125px;
  height: 25vw;
  max-height: 125px;
  background-size: cover;
  background-image: url('./images/icons/travel-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}

.slide-display-item-walking{
  display:block;
  width: 25vw;
  max-width: 125px;
  height: 25vw;
  max-height: 125px;
  background-size: cover;
  background-image: url('./images/icons/walking-icon.png');
  background-repeat: no-repeat;
  background-position: center;
}

.slide-nav{
  display: flex;
  justify-content: center;
}

.slide-nav-btn{
  display: block;
  margin: 0px 25px;
  width: 30px;
}

.slide-statement{
  font-family: 'Bungee Shade';
  font-size: 2rem;
}

/*Mobile*/
.description-grid{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*Mobile*/
.description-grid-1{
  display: flex;
  align-items: center;
  justify-content: center;
}
/*Mobile*/
.description-grid-2{
  width: 325px;
  max-width: 400px;
  text-align: justify;
  text-justify: inter-word;
}


.description-grid-1-content-profile{
  background-image:url('./images/icons/profile-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-website{
  background-image:url('./images/icons/website-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-javascript{
  background-image:url('./images/icons/javascript-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-react{
  background-image:url('./images/icons/react-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-project-management{
  background-image:url('./images/icons/projectmanagement-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-htmlcss{
  background-image:url('./images/icons/htmlcss-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-agile{
  background-image:url('./images/icons/agile-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-pm-tools{
  background-image:url('./images/icons/pmtools-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-api{
  background-image:url('./images/icons/api-icon.png'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-styletheory{
  background-image:url('./images/icons/styletheory-icon.svg'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-crescodata{
  background-image:url('./images/icons/crescodata-icon.svg'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-1-content-pomelo{
  background-image:url('./images/icons/pomelo-icon.svg'); 
  background-repeat:no-repeat;
  background-size: 256px;
  background-position: center;
  width: 256px;
  height: 256px;
}

.description-grid-2-title{
  width: 100%;
  font-family: 'Bungee Shade';
  font-size: 2.5rem;
  color: #2B2E33;
}

/*Mobile*/
.description-grid-2-p{
  height: 70%;
  font-family: 'Aleo';
  font-size: 1rem;
  white-space: pre-line;
  color: #2B2E33;
  margin-top: 10px;
}

.description-grid-3{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.about-btn-me{
  background-image:url('./images/icons/yuhao-btn.svg');
  background-size: 80px 24px;
  background-repeat: no-repeat;
  width: 80px;
  height: 24px;
}

.about-btn-website{
  background-image:url('./images/icons/website-btn.svg');
  background-size: 80px 24px;
  background-repeat: no-repeat;
  width: 80px;
  height: 24px;
}

.about-btn-interests{
  background-image:url('./images/icons/interests-btn.svg');
  background-size: 100px 24px;
  background-repeat: no-repeat;
  width: 100px;
  height: 24px;
}

.skills-btn-javascript{
  background-image:url('./images/icons/javascript-btn.svg');
  background-size: 100px 24px;
  background-repeat: no-repeat;
  width: 100px;
  height: 24px;
}

.skills-btn-react{
  background-image:url('./images/icons/react-btn.svg');
  background-size: 80 24px;
  background-repeat: no-repeat;
  width: 80px;
  height: 24px;
}

.skills-btn-project-management{
  background-image:url('./images/icons/projectmanagement-btn.svg');
  background-size: 200 24px;
  background-repeat: no-repeat;
  width: 200px;
  height: 24px;
}

.skills-btn-htmlcss{
  background-image:url('./images/icons/htmlcss-btn.svg');
  background-size: 100px 24px;
  background-repeat: no-repeat;
  width: 100px;
  height: 24px;
}

.skills-btn-agile{
  background-image:url('./images/icons/agile-btn.svg');
  background-size: 60px 24px;
  background-repeat: no-repeat;
  width: 60px;
  height: 24px;
}

.skills-btn-pm-tools{
  background-image:url('./images/icons/pmtools-btn.svg');
  background-size: 100px 24px;
  background-repeat: no-repeat;
  width: 100px;
  height: 24px;
}

.skills-btn-api{
  background-image:url('./images/icons/api-btn.svg');
  background-size: 60px 24px;
  background-repeat: no-repeat;
  width: 60px;
  height: 24px;
}

.work-btn-styletheory{
  background-image:url('./images/icons/logistics-lead-btn.svg');
  background-size: 120px 48px;
  background-repeat: no-repeat;
  width: 120px;
  height: 48px;
}

.work-btn-crescodata{
  background-image:url('./images/icons/ecommerce-project-manager-btn.svg');
  background-size: 250px 48px;
  background-repeat: no-repeat;
  width: 250px;
  height: 48px;
}

.work-btn-pomelo{
  background-image:url('./images/icons/scrum-master-btn.svg');
  background-size: 120px 48px;
  background-repeat: no-repeat;
  width: 120px;
  height: 48px;
}

.learn-more-btn{
  display: inline-block;
  width: 10vmax;
  min-width: 80px;
  height: 10vmax;
}

/*Blog page css*/

/*Media responsiveness*/
.blogpost-container{
  display:flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 10px;
}

/*Media responsiveness*/
.cardsmall {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, .125);
  border-radius: .25rem;
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
  background-color: #fff;
  border: none;
  border-radius: 0.625rem;
  height: 430px;
}

.cardpost__image {
  position: relative;
  min-height: 13.3125rem;
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.card-title{
  height:34px;
}

.cardText {
  display: block;
  /* or inline-block */
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 4.6em;
  font-family: 'Aleo' -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-weight: 500;
  color: #2B2E33;
  font-size: 16px;
}

.grid {
  margin-bottom: 30px;
}

.grid h5 {
  margin-top: 15px;
}

.grid svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
  width: 0.875em;
  margin-right: 10px;
}

.grid span {
  font-size: 16px;
  font-family: 'Aleo';
  display: inline;
}

.authorimg {
  transform: translateY(5%);
  margin-left: 1.5625rem;
  position: absolute;
  bottom: 0;
}

.authorimg a {
  box-shadow: 0 0 0 0.125rem #fff, 0 0.1875rem 0.4375rem rgba(90, 97, 105, 0.5);
  display: block;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  text-indent: -9999px;
  width: 3.1875rem;
  height: 3.1875rem;
}

.textfiordblue {
  color: #2B2E33;
  font-family: 'Aleo';
}

.text-dark{
  font-family: 'Aleo';
  font-weight: bold;
  color: #2B2E33;
}

.text-muted{
  font-family: 'Aleo';
  color: #C9C7C7;
}

/*######################################################### CLASS SELECTORS ##################################################################*/
/*######################################################### RESPONSIVE ##################################################################*/



/* Small devices (portrait tablets and large phones, 1000px and up) */
@media only screen and (min-width: 660px) {

}


/* Medium devices (landscape tablets, 850px and up) */
@media only screen and (min-width: 850px) {

  .description-grid{
    display:grid;
    grid-template-columns: 2;
    justify-content: center;
  
  }
  
  .description-grid-1{
    grid-column: 1/2;
    height: 300px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description-grid-2{
    grid-column: 2/2;
    width: 30vw;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    text-align: justify;
    text-justify: inter-word;
    flex-wrap: wrap;
  }

  .description-grid-2-p{
    height: 70%;
    font-family: 'Aleo';
    font-size: 1rem;
    white-space: pre-line;
    color: #2B2E33;
  }

.slide-display-item-first{
  display:block;
  width: 125px;
  height: 125px;
  margin: 0px 15px;
}


.slide-display-item-last{
  display:block;
  width: 125px;
  height: 125px;
  margin: 0px 15px;
}
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 990px) {
  /* Set at 990 specifically for Blog page bacause each blogpost is 330px, and it doesn't adjust because of the wrapping*/
  .header{
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    height: 40px;
    box-sizing: border-box;
    padding-top: 17px;
    padding-right: 5px;
    font-family: 'Aleo';
  }
} 

@media only screen and (min-width: 1000px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {

}


/*######################################################### RESPONSIVE ##################################################################*/